<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_provide"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step my-1"
              data-wizard-type="step"
              :data-wizard-state="step.state"
              v-for="step in steps"
              :key="step.title"
            >
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <h3 class="wizard-title mb-0">
                    {{ step.title }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <StepCompanyInformation
                    :is-valid="validation.stepCompanyInformation"
                    :company-name.sync="company.name"
                    :commercial-title.sync="company.commercialTitle"
                  />
                </div>

                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="pending"
                >
                  <StepAuthorizedPerson
                    :is-valid="validation.stepAuthorizedPerson"
                    :title.sync="authorizedPerson.title"
                    :full-name.sync="authorizedPerson.fullName"
                    :phone-number.sync="authorizedPerson.phoneNumber"
                    :email.sync="authorizedPerson.email"
                  />
                </div>

                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="pending"
                >
                  <StepLogo
                    :is-valid="validation.stepLogo"
                    :logo.sync="company.logo"
                  />
                </div>

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <button
                      @click="onSubmit"
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      ref="kt_submit_company"
                      data-wizard-type="action-submit"
                    >
                      Kaydet
                    </button>
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import KTWizard from "@/assets/js/components/wizard";
import StepCompanyInformation from "./steps/StepCompanyInformation.vue";
import StepAuthorizedPerson from "./steps/StepAuthorizedPerson.vue";
import StepLogo from "./steps/StepLogo.vue";

export default {
  name: "TransportCompanyWizard",
  components: {
    StepCompanyInformation,
    StepAuthorizedPerson,
    StepLogo
  },
  props: {
    wizardType: {
      type: String,
      default: "CREATE",
      validator: value => {
        return ["CREATE", "EDIT"].indexOf(value) !== -1;
      }
    },
    prefilledData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isStepCompanyInformationValid() {
      return (
        this.company.name.length > 0 && this.company.commercialTitle.length > 0
      );
    },
    isStepAuthorizedPersonValid() {
      return (
        this.authorizedPerson.fullName.length > 0 &&
        this.authorizedPerson.phoneNumber.length > 0
      );
    },
    isStepLogoValid() {
      return this.company.logo.length > 0;
    }
  },
  data() {
    return {
      wizard: null,
      steps: [
        {
          title: "Firma Bilgileri",
          state: "current"
        },
        {
          title: "Yetkili",
          state: "pending"
        },
        {
          title: "Logo",
          state: "pending"
        }
      ],

      companyId: 0,

      // Step 1
      company: {
        name: "",
        commercialTitle: "",
        logo: ""
      },

      // Step 2
      authorizedPerson: {
        title: "",
        fullName: "",
        phoneNumber: "",
        email: ""
      },

      validation: {
        stepCompanyInformation: true,
        stepAuthorizedPerson: true,
        stepLogo: true
      },
      isSubmitting: false
    };
  },
  async beforeMount() {
    if (this.prefilledData) {
      this.companyId = this.prefilledData.id;
      this.company = { ...this.prefilledData.company };
      this.authorizedPerson = { ...this.prefilledData.authorizedPerson };
    }
  },
  mounted() {
    const self = this;
    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_provide", {
      startStep: 1, // initial active step number
      clickableSteps: false // allow step clicking
    });

    this.wizard.on("change", function(wizardObj) {
      if (wizardObj.newStep < wizardObj.currentStep) {
        return;
      }

      const step = wizardObj.getStep();
      switch (step) {
        case 1:
          if (!self.isStepCompanyInformationValid) {
            self.validation.stepCompanyInformation = false;
            wizardObj.stop();
          } else {
            self.validation.stepCompanyInformation = true;
          }
          break;
        case 2:
          if (!self.isStepAuthorizedPersonValid) {
            self.validation.stepAuthorizedPerson = false;
            wizardObj.stop();
          } else {
            self.validation.stepAuthorizedPerson = true;
          }
          break;
        default:
          break;
      }
    });
  },
  methods: {
    addSpinner() {
      this.$refs["kt_submit_company"].classList.add(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    removeSpinner() {
      this.$refs["kt_submit_company"].classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onSubmit() {
      if (!this.isStepLogoValid) {
        this.validation.stepLogo = false;
        return;
      }
      this.validation.stepLogo = true;

      this.addSpinner();

      if (this.wizardType === "CREATE") {
        this.create();
      } else if (this.wizardType === "EDIT") {
        this.edit();
      } else {
        this.removeSpinner();
      }
    },
    create() {
      const data = {
        companyName: this.company.name,
        commercialTitle: this.company.commercialTitle,
        logo: this.company.logo,
        authPersonTitle: this.authorizedPerson.title,
        authPersonFullName: this.authorizedPerson.fullName,
        authPersonPhone: this.authorizedPerson.phoneNumber,
        authPersonEmail: this.authorizedPerson.email
      };
      ApiService.post("transport-company/create.req.php", data)
        .then(() => {
          this.removeSpinner();
          this.$router.push({
            name: "transport-companies"
          });
        })
        .catch(() => {
          this.removeSpinner();
        });
    },
    edit() {
      const data = {
        companyId: this.companyId,
        companyName: this.company.name,
        commercialTitle: this.company.commercialTitle,
        logo: this.company.logo,
        authPersonTitle: this.authorizedPerson.title,
        authPersonFullName: this.authorizedPerson.fullName,
        authPersonPhone: this.authorizedPerson.phoneNumber,
        authPersonEmail: this.authorizedPerson.email
      };
      ApiService.post("transport-company/edit.req.php", data)
        .then(() => {
          this.removeSpinner();
          this.$router.push({
            name: "transport-companies"
          });
        })
        .catch(() => {
          this.removeSpinner();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  border: 2px solid transparent;
  border-radius: 0.85rem;
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state="current"]:after {
  display: none;
}
</style>
