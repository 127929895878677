<template>
  <section>
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Yetkili kişi
    </h1>
    <p class="font-size-h5 mb-7">
      Firma yetkilisinin bilgilerini girin
    </p>

    <div
      class="alert alert-warning bg-light-warning my-8"
      role="alert"
      v-if="!isValid"
    >
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold"
          >Devam edebilmek için lütfen yetkili kişinin adını ve telefon
          numarasını girin.</span
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label
            class="text-body font-weight-bold pl-1 d-flex align-items-center"
          >
            Ünvanı
          </label>
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Yetkilinin ünvanını girin"
            :value="title"
            @input="onTitleInput"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label
            class="text-body font-weight-bold pl-1 d-flex align-items-center"
          >
            Tam Adı
          </label>
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Yetkilinin tam adını girin"
            :value="fullName"
            @input="onFullNameInput"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label
            class="text-body font-weight-bold pl-1 d-flex align-items-center"
          >
            Telefon Numarası
          </label>
          <input
            type="tel"
            class="form-control form-control-lg"
            placeholder="5xx xxx xxxx"
            :value="phoneNumber"
            @input="onPhoneNumberInput"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label
            class="text-body font-weight-bold pl-1 d-flex align-items-center"
          >
            E-posta
          </label>
          <input
            type="email"
            class="form-control form-control-lg"
            placeholder="E-posta adresini girin"
            :value="email"
            @input="onEmailInput"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "StepAuthorizedPerson",
  props: {
    title: {
      type: String,
      default: ""
    },
    fullName: {
      type: String,
      default: ""
    },
    phoneNumber: {
      type: String,
      default: ""
    },
    email: {
      type: String,
      default: ""
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onTitleInput(event) {
      this.$emit("update:title", event.target.value);
    },
    onFullNameInput(event) {
      this.$emit("update:fullName", event.target.value);
    },
    onPhoneNumberInput(event) {
      this.$emit("update:phoneNumber", event.target.value);
    },
    onEmailInput(event) {
      this.$emit("update:email", event.target.value);
    }
  }
};
</script>

<style></style>
