<template>
  <section>
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Logo
    </h1>
    <p class="font-size-h5 mb-7">
      Taşımacı firmanın logosunu yükleyin.
    </p>

    <div
      class="alert alert-warning bg-light-warning my-8"
      role="alert"
      v-if="!isValid"
    >
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold"
          >Devam edebilmek için lütfen logo yükleyin</span
        >
      </div>
    </div>
    <div class="form-group" v-if="logo.length">
      <figure>
        <img alt="Firma logosu" :src="logoUrl" />
      </figure>
    </div>
    <div class="form-group">
      <file-pond
        name="logo"
        allow-multiple="false"
        accepted-file-types="image/png, image/jpeg"
        max-files="1"
        maxFileSize="100KB"
        imageValidateSizeMinWidth="80"
        imageValidateSizeMaxWidth="120"
        imageValidateSizeMinHeight="70"
        imageValidateSizeMaxHeight="100"
        :file-rename-function="renameFile"
        v-on:removefile="removeFile"
        :server="{ process }"
        label-idle='<div style="width:100%;height:100%;">
          <h3>
            Fotoğrafı Sürükle &amp; Bırak veya <span class="filepond--label-action" tabindex="0">Gözat</span><br>
          </h3>
        </div>'
      />
    </div>
  </section>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size/dist/filepond-plugin-file-validate-size.esm.js";
import FilePondPluginFileRename from "filepond-plugin-file-rename/dist/filepond-plugin-file-rename.esm.js";
import "filepond/dist/filepond.min.css";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginFileRename
);

export default {
  name: "StepLogo",
  props: {
    logo: {
      type: String,
      default: ""
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  components: {
    FilePond
  },
  computed: {
    logoUrl() {
      return `${this.$MEDIA_PROD_ENV}transport-company/logo/${this.logo}`;
    }
  },
  methods: {
    renameFile(file) {
      return Date.now() + "_" + file.name;
    },
    removeFile() {
      this.$emit("update:logo", "");
    },
    process(_fieldName, file, _metadata, load, error, progress, abort) {
      const formData = new FormData();
      formData.append("logo", file, file.name);

      const request = new XMLHttpRequest();
      request.open(
        "POST",
        `${this.$API_PROD_ENV}transport-company/upload-logo.req.php`
      );
      request.setRequestHeader("Authorization", JwtService.getToken());

      request.upload.onprogress = e => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      const self = this;
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          self.$emit("update:logo", file.name);
          load();
        } else {
          // Can call the error method if something is wrong, should exit after
          self.renameFile();
          error();
        }
      };

      request.send(formData);

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort();

          // Let FilePond know the request has been cancelled
          abort();
        }
      };
    }
  }
};
</script>

<style></style>
