<template>
  <section>
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Firma bilgileri
    </h1>
    <p class="font-size-h5 mb-7">
      Taşımacı firmaya ait genel bilgileri girin.
    </p>

    <div
      class="alert alert-warning bg-light-warning my-8"
      role="alert"
      v-if="!isValid"
    >
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold"
          >Devam edebilmek için lütfen firmanın adını ve ticari ünvanını
          girin.</span
        >
      </div>
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
        Firma Adı
      </label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Firma adını girin"
        :value="companyName"
        @input="onCompanyNameInput"
      />
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1 d-flex align-items-center">
        Ticari Ünvanı
      </label>
      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Ticari ünvanı girin"
        :value="commercialTitle"
        @input="onCommercialTitlInput"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "StepCompanyInformation",
  props: {
    companyName: {
      type: String,
      default: ""
    },
    commercialTitle: {
      type: String,
      default: ""
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onCompanyNameInput(event) {
      this.$emit("update:companyName", event.target.value);
    },
    onCommercialTitlInput(event) {
      this.$emit("update:commercialTitle", event.target.value);
    }
  }
};
</script>

<style></style>
